import React from "react"
import styled from "styled-components"

const Small = styled.small`
  color: #ff0048;
`

export default function Home() {
  return (
    <div>
      Hello world!{" "}
      <Small>2021</Small>
    </div>
  )
}
